<template>
  <div class="home">
    <div class="container">
      <v-header :menu="true" />
    </div>
    <div class="section dv8">
      <div class="container">
        <div class="row">
          <div class="d-6 mt4">
            <div class="dt10 db7 mb6">
              <h1>Полноценное внедрение кадрового ЭДО</h1>
            </div>

            <div class="sign">
              <img class="sign-image" src="@/assets/icons/done.svg" />
              <div class="sign-text dl3 ml2">
                Создавайте электронные подписи
              </div>
            </div>
            <div class="sign dt3 mt3">
              <img class="sign-image" src="@/assets/icons/done.svg" />
              <div class="sign-text dl3 ml2">Автоматизируйте HR-процессы</div>
            </div>
            <div class="sign dt3 mt3">
              <img class="sign-image" src="@/assets/icons/done.svg" />
              <div class="sign-text dl3 ml2">
                Работайте с&nbsp;кадровыми документами в&nbsp;едином
                пространстве
              </div>
            </div>

            <div class="dt7 mt6">
              <a href="#" class="button m-center" @click.prevent="showModal">
                Отправить запрос
              </a>
            </div>
          </div>
          <div class="d-5 middle mt5 mb10">
            <img
              src="@/assets/pages/home/hero.jpg"
              data-copyright-source="https://storyset.com/illustration/at-work/amico"
              data-copyright-name="Storyset"
              data-copyright-owner="Storyset"
              data-copyright-profile="https://storyset.com/"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="section dt15">
      <div class="container">
        <div class="row dt2 db10 mb11">
          <div class="d-6" v-onscroll="{ delay: 250 }">
            <h3>О&nbsp;решении</h3>
            <p class="md">
              WorkCat упрощает работу с&nbsp;кадровыми задачами, объединяет
              сотрудников и&nbsp;HR-специалистов в&nbsp;едином интерфейсе.
            </p>
            <p class="md">
              Переводите в&nbsp;электронный вид кадровый документооборот:
              от&nbsp;трудовых договоров и&nbsp;заявлений до&nbsp;приказов
              и&nbsp;распоряжений. Автоматизируйте рутину&nbsp;&mdash;
              подписывайте и&nbsp;быстро согласовывайте документы. Общайтесь
              с&nbsp;коллегами и&nbsp;налаживайте внутренние коммуникации.
            </p>
          </div>
          <div
            class="d-4 d-offset-1 mt4 middle benefit-slider"
            v-onscroll="{ delay: 250 }"
          >
            <v-carousel :cards="sliderCards" />
          </div>
        </div>
      </div>
    </div>

    <div class="section section-dark v15 mt7 mb7" v-onscroll>
      <div class="container">
        <div class="row">
          <div class="d-12 dt10 db7 mt7 d-center">
            <h2>Какие задачи решает WorkCat</h2>
          </div>
        </div>
        <div class="row">
          <div class="d-3 db11 mt6" v-onscroll>
            <div class="card d-center m-center">
              <img
                src="@/assets/icons/downgrade.svg"
                class="d-center m-center"
              />
              <p>
                Снижает расходы на&nbsp;делопроизводство и&nbsp;нагрузку
                на&nbsp;персонал
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 250 }">
            <div class="card d-center m-center">
              <img
                src="@/assets/icons/document.svg"
                class="d-center m-center"
              />
              <p>
                Ускоряет подписание и&nbsp;согласование кадровых документов
              </p>
            </div>
          </div>
          <div class="d-3 db11 mt6" v-onscroll="{ delay: 500 }">
            <div class="card d-center m-center">
              <img src="@/assets/icons/graph.svg" class="d-center m-center" />
              <p>Укрепляет HR-бренд и&nbsp;лояльность сотрудников</p>
            </div>
          </div>
          <div class="d-3 db11 mt6 mb7" v-onscroll="{ delay: 750 }">
            <div class="card d-center m-center">
              <img src="@/assets/icons/hr.svg" class="d-center m-center" />
              <p>
                Ускоряет цифровизацию&nbsp;HR без глобального изменения
                ИТ-систем
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section db15 mv10" v-onscroll>
      <div class="container">
        <div class="row middle">
          <div class="d-12">
            <div class="card card-lg card-m-hide-shadow">
              <div class="row middle">
                <div class="d-6">
                  <h2 class="mb7">
                    Переводите кадровые документы в&nbsp;электронный вид
                  </h2>
                  <a href="#" download class="button m-hide" @click.prevent="showModal"
                    >Проконсультироваться</a
                  >
                </div>
                <div class="d-6 mb7">
                  <v-animated-list :list="animatedList" />
                </div>
                <div class="d-12 d-hide">
                  <a href="#" download class="button m-center"
                    >Получить весь список</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <div class="row middle db10 mb7">
          <div class="d-8 d-center" v-onscroll>
            <h2>Выбирайте уровень цифровизации КЭДО</h2>
          </div>
        </div>
        <div class="row">
          <div class="d-4 mb6" v-onscroll>
            <div class="card card-md">
              <div>
                <p class="h1 card-heading">1</p>
              </div>
              <h4 class="dt4 mt3 mb4">
                Единое пространство для работы в&nbsp;офисе
                и&nbsp;на&nbsp;удалёнке
              </h4>
              <div class="db6 mb4">
                <p>
                  Разработаем для вас мобильное приложение для быстрого доступа
                  к&nbsp;корпоративным сервисам по&nbsp;созданию, подписанию,
                  обмену, согласованию кадровых документов, а&nbsp;также общению
                  с&nbsp;коллегами с&nbsp;любого устройства.
                </p>
                <p>
                  Внедрим внутрениий корпоративный портал для обучения,
                  взаимодействия и&nbsp;управления мотивацией сотрудников.
                  Синхронизируем всё делопроизводство.
                </p>
              </div>
              <router-link
                :to="{ name: 'CorporatePortal' }"
                class="card-button d-center m-center button button-inverse"
                >Узнать больше</router-link
              >
            </div>
          </div>

          <div class="d-4 mb6" v-onscroll="{ delay: 250 }">
            <div class="card card-md">
              <div>
                <p class="h1 card-heading">2</p>
              </div>
              <h4 class="dt4 mt3 mb4">
                Корпоративный удостоверяющий центр для выпуска&nbsp;ЭП
              </h4>
              <div class="db6 mb4">
                <p>
                  Организуем ваш&nbsp;УЦ, чтобы вы&nbsp;сами выпускали тысячи
                  подписей для сотрудников. Можем купить оборудование
                  и&nbsp;настроить&nbsp;ПО, подготовить документацию, обучить
                  персонал.
                </p>
                <p>
                  Больше не&nbsp;придётся покупать дорогие УКЭП или долго ждать
                  авторизации&nbsp;&mdash; несколько секунд, и&nbsp;можно
                  подписывать документы через СМС, мессенджер или почту.
                </p>
              </div>
              <router-link
                :to="{ name: 'VerificationCenter' }"
                class="card-button d-center m-center button button-inverse"
                >Узнать больше</router-link
              >
            </div>
          </div>

          <div class="d-4" v-onscroll="{ delay: 500 }">
            <div class="card card-md">
              <div>
                <p class="h1 card-heading">3</p>
              </div>
              <h4 class="dt4 mt3 mb4">
                Автоматизация HR-процессов и&nbsp;ускорение делопроизводства
              </h4>
              <div class="db6 mb4">
                <p>
                  Автоматизируем сканирование, распознавание, подписание,
                  согласование, пересылку кадровых документов.
                </p>
                <p>
                  Настроим сценарии распознавания, подписания
                  и&nbsp;согласования документов у&nbsp;сотрудников, передачу
                  их&nbsp;в&nbsp;электронный архив. Останется только следить
                  за&nbsp;маршрутом документов в&nbsp;интерфейсе.
                </p>
              </div>
              <router-link
                :to="{ name: 'KedoAutomation' }"
                class="card-button d-center m-center button button-inverse"
                >Узнать больше</router-link
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section mv10" v-onscroll>
      <div class="container">
        <div class="row dt13 db15">
          <div class="d-12">
            <div class="card card-lg card-m-hide-shadow">
              <div class="row middle v6 mv1">
                <div class="d-5 dr10 mb4">
                  <center><img src="@/assets/pages/home/preview.jpg" /></center>
                </div>
                <div class="d-6">
                  <h2 class="db4 mb4">
                    Машиночитаемые доверенности для&nbsp;КЭДО
                  </h2>
                  <p>
                    Создавайте доверенности в&nbsp;несколько кликов, управляйте выпуском и&nbsp;храните в облаке.
                  </p>
                  <div class="dt4 mt3">
                    <a
                      href="https://ediweb.com/ru-ru/solutions/gis/electronic-power-attorney"
                      class="button button-sm m-center dr4 mb4"
                    >
                      Узнать больше
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container dv13 mv10">
        <div class="row">
          <div class="d-12 db7 mb6" v-onscroll>
            <h2>Почему WorkCat</h2>
          </div>
          <div class="d-5" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/rocket.svg" />
              <h4 class="dt4 mt3 b2">Готовые решения</h4>
              <div class="sign-descr">
                Вам ничего не&nbsp;нужно разрабатывать с&nbsp;нуля.
                Мы&nbsp;соберём для вас персональное решение для КЭДО
                на&nbsp;базе технологий и&nbsp;продуктов Ediweb.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll>
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/sheld.svg" />
              <h4 class="dt4 mt3 b2">Защита от&nbsp;рисков</h4>
              <div class="sign-descr">
                Используем защищённые каналы связи и&nbsp;дата-центры
                с&nbsp;резервными мощностями. Документы храним в&nbsp;надёжном
                электронном архиве с&nbsp;фильтрами для быстрой выгрузки нужных
                файлов.
              </div>
            </div>
          </div>

          <div class="d-5" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/trust.svg" />
              <h4 class="dt4 mt3 b2">Опыт в&nbsp;ЭДО</h4>
              <div class="sign-descr">
                Разработчик WorkCat&nbsp;&mdash; доверенный оператор ФНС ЭДО
                <a href="https://ediweb.com/" target="_blank"
                >Ediweb</a>. Работем больше 20&nbsp;лет на&nbsp;рынке электронного
                документооборота.
              </div>
            </div>
          </div>

          <div class="d-6 d-offset-1" v-onscroll="{ delay: 250 }">
            <div class="sign sign-vertical db7 mb6">
              <img class="sign-image" src="@/assets/icons/customize.svg" />
              <h4 class="dt4 mt3 b2">Кастомизация</h4>
              <div class="sign-descr">
                Доработаем функциональность продукта под внутренние процессы,
                адаптируем&nbsp;UI под ваш корпоративный стиль.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section section-light">
      <div class="container" v-onscroll>
        <div class="row db7 mb6">
          <div class="d-offset-1 d-9 dt12 mt7 d-center">
            <h2>Получите персональную консультацию</h2>
            <p class="lg">
              Оставьте заявку, и&nbsp;мы&nbsp;расскажем подробнее
              о&nbsp;функционале, вариантах внедрения и&nbsp;кастомизации,
              проведём презентацию решения.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="d-offset-3 d-6 db12 mb7">
            <v-contact-form action="https://ediweb.com/subscribe/9040b663" :centered="true" :openForm="true" />
          </div>
        </div>
      </div>
    </div>

    <v-modal-wrapper name="contacts">
      <v-contact-form
        action="https://ediweb.com/subscribe/9040b663"
        title="Отправить запрос"
        description="Мы свяжемся с вами, проведём предварительную оценку
        и отправим вам индвидуальное предложение."
      >
      </v-contact-form>
    </v-modal-wrapper>
  </div>
</template>

<script>
const meta = {
  title: "WorkCat − автоматизация кадрового документооборота и HR-процессов",
  description: "Управление подписанием и маршрутизацией кадровых документов. Помощь в запуске проектов по КЭДО",
};

export default {
  name: "Home",
  components: {
    "v-header": () => import("@/components/v-header.vue"),
    "v-carousel": () => import("@/components/v-carousel.vue"),
    "v-modal-wrapper": () => import("@/components/v-modal-wrapper.vue"),
    "v-contact-form": () => import("@/components/v-contact-form.vue"),
    "v-animated-list": () => import("@/components/v-animated-list.vue"),
  },
  metaInfo: {
    title: meta.title,
    meta: [
      {
        name: "og:title",
        content: meta.title,
      },
      {
        name: "description",
        content: meta.description,
      },
      {
        name: "og:description",
        content: meta.description,
      },
    ],
  },
  data() {
    return {
      sliderCards: [
        {
          title: "До 0%",
          text: "снижение потерь документов",
        },
        {
          title: "В 3 раза",
          text: "быстрее внесение данных во внутренние системы",
        },
        {
          title: "В 2 раза",
          text: "меньше нагрузка на отдел персонала",
        },
        {
          title: "До 80%",
          text: "экономия на кадровом документообороте",
        },
      ],
      animatedList: [
        "Уведомления об отпуске",
        "Уведомления о вызове из отпуска",
        "Распоряжения о привлечении к работе в выходные и нерабочие дни",
        "Отпуск по беременности и родам",
        "Отпуск по уходу за ребёнком до 3 лет",
        "Объяснительные записки о дисциплинарных проступках и ущербе работодателю",
        "Заявления на отпуск и увольнение",
        "Графики отпусков",
        "Служебные записки на командировку, заявления на суточные и жильё",
        "Больничные листы",
        "Заявления на получение справок",
        "Докладные записки (о переводе, надбавках и т.д.)",
        "Журналы техники безопасности",
        "Трудовые договоры и дополнительные соглашения",
        "Трудовые книжки и книги учёта",
        "Приказы о приёме на работу, дисциплинарном взыскании и увольнении",
        "Личные карточки работников и должностные инструкции",
        "Договоры о материальной ответственности",
        "Рассчётные листки",
      ],
    };
  },
  methods: {
    showModal() {
      this.$modal.show("contacts");
    },
  },
};
</script>
